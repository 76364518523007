<template>
   <div id="main-wrapper">
      <!-- <Container/> -->
      <section class="blockElement">
         <div class="container">
            <div class="row justify-content-center primarybg round-md topPrimaryBox">
               <div class="col-11 col-md-8 text-start text-md-center py-4 py-md-5">
                  <h1 class="mb-0 text-white">The Insider</h1>
                  <p class="d-block mt-3 mb-0 text-white">Give your customers the future of payment today by reading tips and tutorials, data-driven insights, and success stories from merchants and partners within the Capital Wallet network.</p>
               </div>
            </div>
         </div>
      </section>
      <section class="blockElement pt-0">
         <div class="container">
            <div class="tab d-flex justify-content-center hideMobile">
               <ul class="nav nav-pills">
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 1}]" @click.prevent="tab = 1">Current Trends</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 2}]" @click.prevent="tab = 2">Product Updates & Events</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 3}]" @click.prevent="tab = 3">Blockchain Education</a>
                  </li>
                  <li class="nav-item">
                     <a class="nav-link" href="javascript:void(0);" :class="[{'active':tab == 4}]" @click.prevent="tab = 4">News</a>
                  </li>
               </ul>
            </div>
            <div class="tabContent mt-2 mt-lg-5">
               <div class="showTabs-in-mob" :class="[{'active':tab == 1}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 1">
                     Current Trends
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 1" class="pt-4 pt-lg-0">
                  <div class="row justify-content-center">
                     <div class="col-12 col-xl-10 col-xxl-9">
                        <Carousel class="trendsCarousel m-auto round-lg">
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="trendsCarouselBox round-md">
                                    <div class="row align-items-center flex-row-reverse">
                                       <div class="col-12 col-md-7">
                                          <img src="/assets/images/security-and-privacy.webp" alt="Security and Privacy concerns in Crypto Payments" title="Security and Privacy concerns in Crypto Payments" class="img-fluid trendsCarouselImg d-block" />
                                       </div>
                                       <div class="col-12 col-md-5">
                                          <div class="trendsCarouselBoxText py-4 py-md-0">
                                             <h3 class="mb-0 pb-2 pb-lg-3">Security and Privacy concerns in Crypto Payments</h3>
                                             <p class="mb-0 pb-2 pb-lg-5">Cryptocurrency, with its decentralized nature and encryption techniques, has been touted as a secure way to make digital transactions. However.....</p>
                                             <a class="readMore medium" href="/the-insider-detail">Read full article</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Slide>
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="trendsCarouselBox round-md">
                                    <div class="row align-items-center flex-row-reverse">
                                       <div class="col-12 col-md-7">
                                          <img src="/assets/images/payment-changing.webp" alt="How Are Crypto Payments Changing The E-Commerce Landscape?" title="How Are Crypto Payments Changing The E-Commerce Landscape?" class="img-fluid trendsCarouselImg d-block" />
                                       </div>
                                       <div class="col-12 col-md-5">
                                          <div class="trendsCarouselBoxText py-4 py-md-0">
                                             <h3 class="mb-0 pb-2 pb-lg-3">How Are Crypto Payments Changing The E-Commerce Landscape?</h3>
                                             <p class="mb-0 pb-2 pb-lg-5">The digital age has brought about a revolution in the way we conduct transactions and make payments. Gone are the days of cash and cheques, with credit cards and...</p>
                                             <a class="readMore medium" href="javascript:void(0)">Read full article</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Slide>
                           <Slide :key="slide">
                              <div class="carousel__item position-relatives">
                                 <div class="trendsCarouselBox round-md">
                                    <div class="row align-items-center flex-row-reverse">
                                       <div class="col-12 col-md-7">
                                          <img src="/assets/images/processing-transaction-fees.webp" alt="Impact On Payment Processing And Transaction Fees" title="Impact On Payment Processing And Transaction Fees" class="img-fluid trendsCarouselImg d-block" />
                                       </div>
                                       <div class="col-12 col-md-5">
                                          <div class="trendsCarouselBoxText py-4 py-md-0">
                                             <h3 class="mb-0 pb-2 pb-lg-3">Impact On Payment Processing And Transaction Fees</h3>
                                             <p class="mb-0 pb-2 pb-lg-5">The world of finance and payment processing has undergone a massive transformation in recent years with the advent of technology. The introduction...</p>
                                             <a class="readMore medium" href="javascript:void(0)">Read full article</a>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </Slide>
                           <template #addons>
                              <Navigation />
                              <Pagination />
                           </template>
                        </Carousel>
                     </div>
                  </div>
                  <div class="row mt-4 mt-md-5">
                     <div class="col-12 mb-md-5">
                        <div class="d-flex align-items-center justify-content-between searchHeading">
                           <h2 class="mb-0">Recent Articles and News</h2>
                           <div class="d-flex align-items-center py-4 py-md-0">
                              <a href="javascript:void(0)" class=" rotate flex-shrink-0 me-3 mt-3">
                                 <vue-feather class="semibold" type="sliders" size="24"></vue-feather>
                              </a>
                              <div class="position-relative searchBox d-flex justify-content-end">
                                 <input type="text" placeholder="Search all Cryptos" class="form-control round-sm" name="">
                                 <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                                    <vue-feather class="ms-2 semibold" type="search" size="22"></vue-feather>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="bordered p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/articles/consumer-behaviour.webp" alt="Consumer behaviour and attitude towards Cryptocurrency" title="Consumer behaviour and attitude towards Cryptocurrency" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Consumer behaviour and attitude towards Cryptocurrency</a></h6>
                                 <p class="mb-3">Cryptocurrencies, once a niche concept in the world of finance, have now become a topic of mainstream discussion. As the adoption and use of digital currencies continue to grow..</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/payment-system.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Integration with Payment Systems and E-Commerce platforms</a></h6>
                                 <p class="mb-3">With the increasing popularity of cryptocurrencies, there has been a growing demand for their integration with existing payment systems and e-commerce platforms.</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/regenerative-finance.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Introduction to Regenerative Finance</a></h6>
                                 <p class="mb-3">
                                    Regenerative finance is a new and innovative approach to finance that seeks to create a more sustainable and equitable economy. 
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/receive-cryptocurrency.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">How to send, transfer, and receive cryptocurrency?</a></h6>
                                 <p class="mb-3">
                                    The era of crypto is now. More than ever before, customers and merchants are using cryptocurrencies like Bitcoin, Ethereum, and other altcoins in staggering amount.
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/blockchain-technology.webp" alt="Everything you need to know about Blockchain Technology" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Everything you need to know about Blockchain Technology</a></h6>
                                 <p class="mb-3">
                                    Many are familiar with the term Blockchain, the underlying technology that powers Bitcoin, but many people struggle to understand how the technology works. 
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/central-banks.webp" alt="How are central banks around the world embracing cryptocurrency?" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">How are central banks around the world embracing cryptocurrency?</a></h6>
                                 <p class="mb-3">
                                    How should central banks respond to the emergence of blockchain technology? The answer is partly that both they and governments have to get a grip on the new wild west of private money
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="d-flex justify-content-center mt-4"><a href="javascript:void(0);" class="themeBtn medium">View More</a></div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 2}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 2">
                     Product Updates & Events
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 2" class="pt-4 pt-lg-0">
                  <div class="row">
                     <div class="col-12">
                        <div class="timeline">
                           <div class="year">
                              <div class="inner">
                                 <p>January 2022</p>
                              </div>
                           </div>
                           <ul class="listTimeline">
                              <li class="day">
                                 <div class="events">
                                    <div class="card-body border p-0">
                                       <span class="timelineImg d-flex justify-content-center align-items-center primarybg"><img class="d-block img-fluid" src="/assets/images/ecommerce-plugin.webp" alt="Image"></span>
                                       <div class="content p-4">
                                          <div class="themecolor semibold d-flex align-items-center justify-content-between mb-2">
                                             <h4 class="mb-0">E-Commerce Plugin</h4>
                                             <div class="showDate">
                                                <span class="lightgreybg">20</span><span class="secondarybg f-16 text-white">Jan</span>
                                             </div>
                                          </div>
                                          <p class="mb-3">Recently we’ve added several updates and new features to Capital Wallet. From now...</p>
                                          <a class="semibold readMore" href="javascript:void(0)">Read More</a>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="day left">
                                 <div class="events">
                                    <div class="card-body border p-0">
                                       <span class="timelineImg d-flex justify-content-center align-items-center primarybg"><img class="d-block img-fluid" src="/assets/images/auto-conversion-features.webp" alt="Image"></span>
                                       <div class="content p-4">
                                          <div class="themecolor semibold d-flex align-items-center justify-content-between mb-2">
                                             <h4 class="mb-0">Auto Conversion Feature</h4>
                                             <div class="showDate">
                                                <span class="lightgreybg">5</span><span class="secondarybg f-16 text-white">Jan</span>
                                             </div>
                                          </div>
                                          <p class="mb-3">Capital Wallet believes in the promise of Bitcoin as a currency that enables access...</p>
                                          <a class="semibold readMore" href="javascript:void(0)">Read More</a>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                           <div class="year mtCustom">
                              <div class="inner">
                                 <p>December 2021</p>
                              </div>
                           </div>
                           <ul class="listTimeline">
                              <li class="day">
                                 <div class="events">
                                    <div class="card-body border p-0">
                                       <span class="timelineImg d-flex justify-content-center align-items-center primarybg"><img class="d-block img-fluid" src="/assets/images/scheduled-bank.webp" alt="Image"></span>
                                       <div class="content p-4">
                                          <div class="themecolor semibold d-flex align-items-center justify-content-between mb-2">
                                             <h4 class="mb-0">Scheduled Bank Transfers</h4>
                                             <div class="showDate">
                                                <span class="lightgreybg">15</span><span class="secondarybg f-16 text-white">Dec</span>
                                             </div>
                                          </div>
                                          <p class="mb-3">We’ve updated the functionality of our bank transfer service. Our new scheduled bank....</p>
                                          <a class="semibold readMore" href="javascript:void(0)">Read More</a>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                              <li class="day left">
                                 <div class="events">
                                    <div class="card-body border p-0">
                                       <span class="timelineImg d-flex justify-content-center align-items-center primarybg"><img class="d-block img-fluid" src="/assets/images/payment-templates.webp" alt="Image"></span>
                                       <div class="content p-4">
                                          <div class="themecolor semibold d-flex align-items-center justify-content-between mb-2">
                                             <h4 class="mb-0">Payment Templates</h4>
                                             <div class="showDate">
                                                <span class="lightgreybg">1</span><span class="secondarybg f-16 text-white">Dec</span>
                                             </div>
                                          </div>
                                          <p class="mb-3">Today, we’re updating the Capital Wallet dashboard application. This update has a....</p>
                                          <a class="semibold readMore" href="javascript:void(0)">Read More</a>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                           <div class="year mtCustom last-child position-relative">
                              <div class="inner">
                                 <p>November 2021</p>
                              </div>
                           </div>
                           <ul class="listTimeline">
                              <li class="day">
                                 <div class="events border-0">
                                    <div class="card-body border p-0">
                                       <span class="timelineImg d-flex justify-content-center align-items-center primarybg"><img class="d-block img-fluid" src="/assets/images/usdc-coin.webp" alt="Image"></span>
                                       <div class="content p-4">
                                          <div class="themecolor semibold d-flex align-items-center justify-content-between mb-2">
                                             <h4 class="mb-0">New Coin Added: USDC</h4>
                                             <div class="showDate">
                                                <span class="lightgreybg">8</span><span class="secondarybg f-16 text-white">Nov</span>
                                             </div>
                                          </div>
                                          <p class="mb-3">We continue adding various cryptocurrencies and coins to the platform..</p>
                                          <a class="semibold readMore" href="javascript:void(0)">Read More</a>
                                       </div>
                                    </div>
                                 </div>
                              </li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div class="row mt-5">
                     <div class="col-12 mb-md-5">
                        <div class="d-flex align-items-center justify-content-between searchHeading
                           ">
                           <h2 class="mb-0">All Events</h2>
                           <div class="d-flex align-items-center py-4 py-md-0">
                              <a href="javascript:void(0)" class=" rotate flex-shrink-0 me-3 mt-3">
                                 <vue-feather class="semibold" type="sliders" size="24"></vue-feather>
                              </a>
                              <div class="position-relative searchBox d-flex justify-content-end">
                                 <input type="text" placeholder="Search all Cryptos" class="form-control round-sm" name="">
                                 <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                                    <vue-feather class="ms-2 semibold" type="search" size="22"></vue-feather>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-md-4 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="bordered p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/ifx-expo-dubai-2023.webp" alt="iFX Expo Dubai 2023" title="iFX Expo Dubai 2023" width="435" height="247" /></div>
                           <div class="p-3 p-xxl-4">
                              <div class="eventContent">
                                 <div class="d-flex align-items-center justify-content-between dateTime mb-3">
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="clock"></vue-feather>
                                       10 April 2022
                                    </p>
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="map-pin"></vue-feather>
                                       Zoom
                                    </p>
                                 </div>
                                 <h6>iFX Expo Dubai 2023</h6>
                                 <p class="mb-3">The iFX Expo Dubai is fast approaching and we can't wait to join over thousands of our industry experts. Come find us at booth 135, near Idea Hub.</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between pt-2 eventBtn">
                                 <a href="javascript:void(0);" class="borderBtn medium mb-3 mb-xl-0">Event Details</a>
                                 <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">Register Now</a>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-4 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="bordered p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/metaverse-blockchain.webp" alt="FREE WEBINAR: The Metaverse & Blockchain" title="FREE WEBINAR: The Metaverse & Blockchain" width="436" height="247" /></div>
                           <div class="p-3 p-xxl-4">
                              <div class="eventContent">
                                 <div class="d-flex align-items-center justify-content-between dateTime mb-3">
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="clock"></vue-feather>
                                       30 March 2023
                                    </p>
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="map-pin"></vue-feather>
                                       Zoom
                                    </p>
                                 </div>
                                 <h6>FREE WEBINAR: The Metaverse & Blockchain</h6>
                                 <p class="mb-3">The metaverse is emerging as a framework to bring together digital technologies like AR, VR, and blockchain to form new immersive virtual world experiences..</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between pt-2 eventBtn">
                                 <a href="javascript:void(0);" class="borderBtn medium mb-3 mb-xl-0">Event Details</a>
                                 <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium">Register Now</a>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-4 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="bordered p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/live-crypto-payments.webp" alt="LIVE: Crypto Payments 101" title="LIVE: Crypto Payments 101" width="436" height="247" /></div>
                           <div class="p-3 p-xxl-4">
                              <div class="eventContent">
                                 <div class="d-flex align-items-center justify-content-between dateTime mb-3">
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="clock"></vue-feather>
                                       1 Dec 2022
                                    </p>
                                    <p class="mb-0 f-16 d-flex align-items-center">
                                       <vue-feather size="16px" class="me-2" type="map-pin"></vue-feather>
                                       Zoom
                                    </p>
                                 </div>
                                 <h6>LIVE: Crypto Payments 101</h6>
                                 <p class="mb-3">You can ask any questions related to Bitcoin, Blockchain, Other alternate coins such as Dodgecoin, Bitcoin in this live...</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between pt-2 eventBtn">
                                 <a href="javascript:void(0);" class="borderBtn medium mb-3 mb-xl-0">Event Details</a>
                                 <a :href="(entityName != '') ? static_vars.merchantPortalLinks[entityName].register : ''" class="themeBtn medium disabled">Register Now</a>
                              </div>
                           </div>
                        </article>
                     </div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 3}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 3">
                     Blockchain Education
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 3" class="pt-4 pt-lg-0">
                  <div class="row mt-5">
                     <div class="col-12 mb-md-5">
                        <div class="d-flex align-items-center justify-content-between searchHeading">
                           <h2 class="mb-0">All Articles</h2>
                           <div class="d-flex align-items-center py-4 py-md-0">
                              <a href="javascript:void(0)" class=" rotate flex-shrink-0 me-3 mt-3">
                                 <vue-feather class="semibold" type="sliders" size="24"></vue-feather>
                              </a>
                              <div class="position-relative searchBox d-flex justify-content-end">
                                 <input type="text" placeholder="Search all Cryptos" class="form-control round-sm" name="">
                                 <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                                    <vue-feather class="ms-2 semibold" type="search" size="22"></vue-feather>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="bordered p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/articles/consumer-behaviour.webp" alt="Consumer behaviour and attitude towards Cryptocurrency" title="Consumer behaviour and attitude towards Cryptocurrency" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Consumer behaviour and attitude towards Cryptocurrency</a></h6>
                                 <p class="mb-3">Cryptocurrencies, once a niche concept in the world of finance, have now become a topic of mainstream discussion. As the adoption and use of digital currencies continue to grow..</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/payment-system.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Integration with Payment Systems and E-Commerce platforms</a></h6>
                                 <p class="mb-3">With the increasing popularity of cryptocurrencies, there has been a growing demand for their integration with existing payment systems and e-commerce platforms.</p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/regenerative-finance.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Introduction to Regenerative Finance</a></h6>
                                 <p class="mb-3">
                                    Regenerative finance is a new and innovative approach to finance that seeks to create a more sustainable and equitable economy. 
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/regenerative-finance.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">How to send, transfer, and receive cryptocurrency?</a></h6>
                                 <p class="mb-3">
                                    The era of crypto is now. More than ever before, customers and merchants are using cryptocurrencies like Bitcoin, Ethereum, and other altcoins in staggering amount.
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/receive-cryptocurrency.webp" alt="Everything you need to know about Blockchain Technology" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">Everything you need to know about Blockchain Technology</a></h6>
                                 <p class="mb-3">
                                    Many are familiar with the term Blockchain, the underlying technology that powers Bitcoin, but many people struggle to understand how the technology works. 
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="col-12 col-md-6 col-lg-4 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                        <article class="border p-0 h-100 round-md">
                           <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/central-banks.webp" alt="How are central banks around the world embracing cryptocurrency?" width="436" height="247" /></div>
                           <div class="py-3 px-3 px-xl-4">
                              <div class="articleContent">
                                 <h6><a href="javascript:void(0);">How are central banks around the world embracing cryptocurrency?</a></h6>
                                 <p class="mb-3">
                                    How should central banks respond to the emergence of blockchain technology? The answer is partly that both they and governments have to get a grip on the new wild west of private money
                                 </p>
                              </div>
                              <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                 <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                 <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                 <span class="ms-2 ms-xl-3">CW Team</span>
                                 </a>
                                 <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                              </div>
                           </div>
                        </article>
                     </div>
                     <div class="d-flex justify-content-center mt-4"><a href="javascript:void(0);" class="themeBtn medium">View More</a></div>
                  </div>
               </div>
               <div class="showTabs-in-mob pt-3 pb-lg-0" :class="[{'active':tab == 4}]">
                  <a class="nav-link round-sm px-3 py-2 semibold" href="javascript:void(0);" @click.prevent="tab = 4">
                     News
                     <vue-feather size="25px" type="chevron-down"></vue-feather>
                  </a>
               </div>
               <div v-if="tab == 4" class="pt-4 pt-lg-0">
                  <div class="row justify-content-between">
                     <h2 class="mb-3 mb-xl-4 mb-xxl-5">Top News</h2>
                     <div class="col-12 col-md-6 pe-xl-4 pe-xxl-5 pb-4 pb-md-0">
                        <div class="newUpdate position-relative">
                           <img class="d-block w-100 round-md" src="/assets/images/news-1.webp" alt="Image"><small class="redbg whitecolor f-14">Featured</small>
                           <h6 class="semibold m-0 position-absolute"><a href="javascript:void(0);">Biden’s Administration To Release Crypto Strategy on Digital Assets Next Month</a></h6>
                        </div>
                     </div>
                     <div class="col-12 col-md-6 ps-xl-4 ps-xxl-5 pb-4 pb-md-0">
                        <div class="newUpdate position-relative">
                           <img class="d-block w-100 round-md" src="/assets/images/news-2.webp" alt="Image"><small class="redbg whitecolor f-14">Featured</small>
                           <h6 class="semibold m-0 position-absolute"><a href="javascript:void(0);">Kosovo Bans Cryptpocurrency Mining To Save Electricity Amid Blackouts</a></h6>
                        </div>
                     </div>
                  </div>
                  <div class="mt-md-4 mt-xl-5 carouselNews">
                     <carousel :settings="newsSettings" :breakpoints="breakpoints" :wrap-around="false">
                        <slide :key="1">
                           <div class="newUpdate position-relative bordered round-md">
                              <img class="d-block w-100" src="/assets/images/news-3.webp" alt="Image">
                              <small class="secondarybg whitecolor f-14">Featured</small>
                              <h6 class="semibold mb-0 bg-white"><a href="javascript:void(0);">Twitter Launches Verification Mechanism for NFT Avatars</a></h6>
                           </div>
                        </slide>
                        <slide :key="2">
                           <div class="newUpdate position-relative bordered round-md">
                              <img class="d-block w-100" src="/assets/images/news-4.webp" alt="Image">
                              <small class="yellowbg whitecolor f-14">Featured</small>
                              <h6 class="semibold mb-0 bg-white"><a href="javascript:void(0);">Google Forms New Team to Shape Blockchain Venture</a></h6>
                           </div>
                        </slide>
                        <slide :key="3">
                           <div class="newUpdate position-relative bordered round-md">
                              <img class="d-block w-100" src="/assets/images/news-5.webp" alt="Image">
                              <h6 class="semibold mb-0 bg-white"><a href="javascript:void(0);">NYC Mayor to Receive 1st Paycheck in Crypto-converted</a></h6>
                           </div>
                        </slide>
                        <slide :key="4">
                           <div class="newUpdate position-relative bordered round-md">
                              <img class="d-block w-100" src="/assets/images/news-5.webp" alt="Image">
                              <h6 class="semibold mb-0 bg-white"><a href="javascript:void(0);">NYC Mayor to Receive 1st Paycheck in Crypto-converted</a></h6>
                           </div>
                        </slide>
                        <template #addons>
                           <navigation />
                        </template>
                     </carousel>
                  </div>
                  <div class="mt-5 pt-3 pt-xl-0">
                     <div class="mb-md-5">
                        <div class="d-flex align-items-center justify-content-between searchHeading">
                           <h2 class="mb-0">All News Articles</h2>
                           <div class="d-flex align-items-center py-4 py-md-0">
                              <a href="javascript:void(0)" class=" rotate flex-shrink-0 me-3 mt-3">
                                 <vue-feather class="semibold" type="sliders" size="24"></vue-feather>
                              </a>
                              <div class="position-relative searchBox d-flex justify-content-end">
                                 <input type="text" placeholder="Search all Cryptos" class="form-control round-sm" name="">
                                 <a href="javascript:void(0)" class="searchBoxIcon position-absolute">
                                    <vue-feather class="ms-2 semibold" type="search" size="22"></vue-feather>
                                 </a>
                              </div>
                           </div>
                        </div>
                     </div>
                     <div class="row">
                        <div class="col-12 col-md-6 col-lg-8">
                           <div class="row">
                              <div class="col-12 col-lg-6 mb-4 aos-init aos-animate mb-4" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                                 <article class="bordered p-0 h-100 round-md">
                                    <div class="w-100 articleImg"><img class="d-block w-100 img-fluid articleImg" src="/assets/images/articles/consumer-behaviour.webp" alt="Consumer behaviour and attitude towards Cryptocurrency" title="Consumer behaviour and attitude towards Cryptocurrency" width="436" height="247" /></div>
                                    <div class="py-3 px-4">
                                       <div class="articleContent">
                                          <h6><a href="javascript:void(0);">Consumer behaviour and attitude towards Cryptocurrency</a></h6>
                                          <p class="mb-3">Cryptocurrencies, once a niche concept in the world of finance, have now become a topic of mainstream discussion. As the adoption and use of digital currencies continue to grow..</p>
                                       </div>
                                       <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                          <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                          <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                          <span class="ms-3">CW Team</span>
                                          </a>
                                          <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                                       </div>
                                    </div>
                                 </article>
                              </div>
                              <div class="col-12 col-lg-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                                 <article class="border p-0 h-100 round-md">
                                    <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/payment-system.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                                    <div class="py-3 px-4">
                                       <div class="articleContent">
                                          <h6><a href="javascript:void(0);">Integration with Payment Systems and E-Commerce platforms</a></h6>
                                          <p class="mb-3">With the increasing popularity of cryptocurrencies, there has been a growing demand for their integration with existing payment systems and e-commerce platforms.</p>
                                       </div>
                                       <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                          <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                          <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                          <span class="ms-3">CW Team</span>
                                          </a>
                                          <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                                       </div>
                                    </div>
                                 </article>
                              </div>
                              <div class="col-12 col-lg-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                                 <article class="border p-0 h-100 round-md">
                                    <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/regenerative-finance.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                                    <div class="py-3 px-4">
                                       <div class="articleContent">
                                          <h6><a href="javascript:void(0);">Introduction to Regenerative Finance</a></h6>
                                          <p class="mb-3">
                                             Regenerative finance is a new and innovative approach to finance that seeks to create a more sustainable and equitable economy. 
                                          </p>
                                       </div>
                                       <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                          <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                          <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                          <span class="ms-3">CW Team</span>
                                          </a>
                                          <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                                       </div>
                                    </div>
                                 </article>
                              </div>
                              <div class="col-12 col-lg-6 mb-4 aos-init aos-animate" data-aos="fade-up" data-aos-duration="1600" index="0" value="[object Object]">
                                 <article class="border p-0 h-100 round-md">
                                    <div class="w-100 articleImg"><img class="d-block w-100 img-fluid" src="/assets/images/articles/receive-cryptocurrency.webp" alt="Integration with Payment Systems and E-Commerce platforms" title="Integration with Payment Systems and E-Commerce platforms" width="436" height="247" /></div>
                                    <div class="py-3 px-4">
                                       <div class="articleContent">
                                          <h6><a href="javascript:void(0);">How to send, transfer, and receive cryptocurrency?</a></h6>
                                          <p class="mb-3">
                                             The era of crypto is now. More than ever before, customers and merchants are using cryptocurrencies like Bitcoin, Ethereum, and other altcoins in staggering amount.
                                          </p>
                                       </div>
                                       <div class="d-flex align-items-center justify-content-between borderedT pt-3 mt-4">
                                          <a title="CW Team" href="javascript:void(0)" class="d-flex align-items-center borderedR w-50">
                                          <img class="d-block img-fluid rounded-pill" src="/assets/images/articles/cw-team.webp" alt="CW Team" title="CW Team" width="28" height="28" />
                                          <span class="ms-3">CW Team</span>
                                          </a>
                                          <p class="mb-0 f-16 w-50 text-end">3 MIN READ</p>
                                       </div>
                                    </div>
                                 </article>
                              </div>
                              <div class="d-flex justify-content-center mt-4"><a href="javascript:void(0);" class="themeBtn medium">View More</a></div>
                           </div>
                        </div>
                        <div class="col-12 col-md-6 col-lg-4 pt-5 pt-md-0">
                           <div class="articleList customPad-left">
                              <div class="mb-5 borderedB pb-4">
                                 <div class="d-flex align-items-center justify-content-between mb-4">
                                    <h3 class="mb-0">Editor’s Choice</h3>
                                    <a class="readMore medium" href="javascript:void(0)">View More</a>
                                 </div>
                                 <img src="/assets/images/editor-choice.webp" alt="Editor’s Choice" title="Editor’s Choice" width="457" height="259" class="img-fluid round-lg">
                                 <ul class="listdDot my-4">
                                    <li><a href="javascript:void(0)">Bitcoin ‘enters value zone’ as BTC price floor metric goes green again</a></li>
                                    <li><a href="javascript:void(0)">No regrets for NYC mayor receiving his first Bitcoin paycheck during dip</a></li>
                                    <li><a href="javascript:void(0)">Bank of Korea completes first phase of digital currency pilot</a></li>
                                 </ul>
                              </div>
                              <div class="mb-md-4 pb-md-4">
                                 <div class="d-flex align-items-center justify-content-between mb-4">
                                    <h3 class="mb-0">Top Headline</h3>
                                    <a class="readMore medium" href="javascript:void(0)">View More</a>
                                 </div>
                                 <img src="/assets/images/top-headline.webp" alt="Top Headline" title="Top Headline" width="457" height="259" class="img-fluid round-lg">
                                 <ul class="listdDot my-4">
                                    <li><a href="javascript:void(0)">Bitcoin Dips to Lowest Level in Five Months below $39k</a></li>
                                    <li><a href="javascript:void(0)">Central Bank of Russia intends to Ban Crypto Mining and Activites</a></li>
                                 </ul>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
      <!-- <Footer /> -->
      <section class="blockElement primarybg" data-aos="fade-up" data-aos-duration="1800">
         <div class="container">
            <div class="row justify-content-center">
               <div class="col-12 col-xl-10 text-center">
                  <div class="criptoIcon">
                     <span class="left-tp tow"><img src="/assets/images/left-mail.webp" alt="Icon" title="left mail" width="231" height="168" class="img-fluid"></span>
                     <span class="left-tp three"><img src="/assets/images/right-mail.webp" alt="Icon" title="right mail" width="87" height="83" class="img-fluid"><img src="/assets/images/left-mail-1.webp" alt="Icon" title="right mail" width="69" height="66" class="img-fluid onSmall"></span>
                  </div>
                  <div class="content position-relative">
                     <h2 class="whitecolor">Stay Connected</h2>
                     <p class="whitecolor regular mb-0">Subscribe to our newsletter and stay up-to-date with our product updates, releases and news</p>
                     <div class="position-relative mt-5 newsletterBox">
                        <input type="text" placeholder="Email Address" class="form-control max-100 rounded-pill border-0" name="">
                        <a href="javascript:void(0)" class="newsletterBtn l-1 position-absolute rounded-pill">Subscribe</a>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
   </div>
</template>
<script>
import "vue3-carousel/dist/carousel.css";
   import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
   
   
   export default ({
      data() {
         return {
            tab: 1,
            newsSettings: {
               snapAlign: 'start',
               breakpoints: {
                  320: {
                     itemsToShow: 1,
                     snapAlign: 'start',
                  },
                  768: {
                     itemsToShow: 2,
                     snapAlign: 'start',
                  },
                  992: {
                     itemsToShow: 2,
                     itemsToScroll: 1,
                     snapAlign: 'start',
                  },
                  1200: {
                     itemsToShow: 3,
                     snapAlign: 'start',
                  },
               },
            },
         };
      },
      components: { Carousel, Slide, Pagination,  Navigation },
   });
   
</script>